import { ItemDataType } from "@/types";

export const itemsPerPageOptions = [
  { value: 20, name: "20 items per page" },
  { value: 50, name: "50 items per page" },
  { value: 100, name: "100 items per page" },
  { value: 500, name: "500 items per page" },
  { value: 1000, name: "1000 items per page" },
];

export const mediaTypeFilterOptions: {
  value: "all" | ItemDataType;
  name: string;
}[] = [
  { value: "all", name: "All media types" },
  { value: ItemDataType.IMAGE, name: "Image" },
  { value: ItemDataType.AUDIO, name: "Audio" },
  { value: ItemDataType.VIDEO, name: "Video" },
  { value: ItemDataType.TEXT, name: "Text" },
  // this functionality will be added later
  // { value: "code", name: "Code" },
];

export const inDatasetFilterOptions: {
  value: "all" | "inDataset";
  name: string;
}[] = [
  { value: "all", name: "All images" },
  { value: "inDataset", name: "Images found in dataset" },
];

export const contactEmail = "info@spawning.ai";
