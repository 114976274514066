"use client";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../app/GlobalRedux/store";
import MoreLikeThisModal from "../../MoreLikeThisModal/MoreLikeThisModal";
import HibtService from "@/services/hibtService";
import styles from "./SearchPageLayout.module.scss";
import { setListsInformation } from "../../../app/GlobalRedux/Features/authSlice";
import Logging from "@/utils/Logging";
import NavBar from "@/components/NavBar/NavBar";

interface SearchPageLayoutProps {
  children: React.ReactNode;
}

const SearchPageLayout: React.FC<SearchPageLayoutProps> = ({ children }) => {
  const dispatch = useDispatch();
  const modalInfo = useAppSelector((state) => state.moreLikeThis);
  const userData = useAppSelector((state) => state.auth.user);

  const isOpen = useAppSelector((state) => state.moreLikeThis.isOpen);

  useEffect(() => {
    if (!userData) {
      return;
    }

    const loadData = async () => {
      try {
        const { lists } = await HibtService.getLists(userData?.id_token);

        dispatch(setListsInformation(lists));
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        Logging.networkError("API Error - get user's lists", {
          pageName: "Search page layout",
          errorMessage: error.message,
          errorCode: error.code,
          statusCode: error.response?.status,
        });
      }
    };

    loadData();
  }, [userData, dispatch]);

  return (
    <div
      style={{
        overflow: isOpen ? "hidden" : "auto",
        position: isOpen ? "fixed" : "relative",
        height: "100%",
        minHeight: "100vh",
      }}
    >
      <NavBar />
      <div className={styles.wrapper}>
        {children}

        {modalInfo.isOpen && (
          <MoreLikeThisModal
            image_url={modalInfo.image_url}
            caption={modalInfo.caption || "no caption found"}
          />
        )}
      </div>
    </div>
  );
};

export default SearchPageLayout;
