"use client";
import React, { useState } from "react";
import Image from "next/image";
import { Pagination } from "@mui/material";
import styles from "./InfoSection.module.scss";
import { AuthPhase, SearchType } from "@/types";
import Button from "../Button/Button";
import { useAppSelector } from "@/app/GlobalRedux/store";
import { useDispatch } from "react-redux";
import { setAuthenticationPhase } from "@/app/GlobalRedux/Features/authSlice";
import { setAuthModalsState } from "@/app/GlobalRedux/Features/authModalsSlice";
import HibtService from "@/services/hibtService";
import Logging from "@/utils/Logging";
import { setSnackBar } from "@/app/GlobalRedux/Features/snackBarSlice";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";

interface InfoSectionProps {
  valueSearched: {
    value: string;
    type: SearchType | "EXTENSION_RESULTS" | "SHARE_RESULTS";
  };
  label: string;
  tooltipText?: string;
  tooltipIcon?: React.ReactNode;
  caption?: string;
  currentPage: number;
  itemsPerPage: number;
  paginationCount: number;
  handlePageChange: (event: React.ChangeEvent<unknown>, value: number) => void;
}

const InfoSection: React.FC<InfoSectionProps> = ({
  valueSearched,
  label,
  tooltipText,
  caption,
  currentPage,
  itemsPerPage,
  paginationCount,
  handlePageChange,
}) => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useAppSelector((state) => state.auth);
  const user = useAppSelector((state) => state.auth.user);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const handleClaimDomain = async () => {
    if (!isAuthenticated) {
      // show login modal
      dispatch(setAuthenticationPhase(AuthPhase.LOGIN));
      dispatch(setAuthModalsState({ isLogInModalOpen: true }));
      return;
    }

    if (!user?.id_token) {
      return;
    }

    try {
      await HibtService.registerDomain(user?.id_token, {
        name: valueSearched.value,
        type_id: 1,
      });

      dispatch(
        setSnackBar({
          open: true,
          message: `${valueSearched.value} registered successfully`,
          variant: "success",
        })
      );

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      Logging.networkError("API Error - registerDomain", {
        pageName: "Domains",
        errorMessage: error.message,
        errorCode: error.code,
        statusCode: error.response?.status,
      });

      dispatch(
        setSnackBar({
          open: true,
          message: "Error while registering domain",
          variant: "error",
        })
      );
    }
  };

  const valueDisplay: Record<
    SearchType | "EXTENSION_RESULTS" | "SHARE_RESULTS",
    React.ReactNode
  > = {
    [SearchType.DOMAIN]: (
      <div className={styles.domainInfoWrapper}>
        <div className={styles.domainInfoContent}>
          <div className={styles.infoSectionContent}>
            <div className={styles.infoSectionLeft}>
              <h2 className={styles.label}>{label}:</h2>
            </div>
            <h1 className={styles.valueSearched}>{valueSearched.value}</h1>
          </div>
          <p>{tooltipText}</p>
        </div>
        <div className={styles.claimDomainSection}>
          <Button onClick={() => setIsConfirmationModalOpen(true)}>
            Claim this domain
          </Button>
        </div>
      </div>
    ),
    [SearchType.IMAGE]: (
      <div className={styles.infoSectionContent}>
        <Image
          src={valueSearched.value}
          alt={caption || "no caption found"}
          width={60}
          height={0}
          style={{ height: "auto" }}
        />
        <div className={styles.infoSectionLeft}>
          <h2 className={styles.label}>{label}</h2>
          <p>{tooltipText}</p>
        </div>
      </div>
    ),
    [SearchType.LITERATURE]: (
      <h1 className={styles.valueSearched}>{valueSearched.value}</h1>
    ),
    [SearchType.TEXT]: (
      <div className={styles.infoSectionContent}>
        <div className={styles.infoSectionLeft}>
          <h2 className={styles.label}>{label} :</h2>
        </div>
        <h1 className={styles.valueSearched}>{valueSearched.value}</h1>
      </div>
    ),
    ["EXTENSION_RESULTS"]: null,
    [SearchType.NONE]: <span>error</span>,
    ["SHARE_RESULTS"]: (
      <div className={styles.shareResultsInfoSectionContent}>
        <div className={styles.infoSectionLeft}>
          <h2 className={styles.label}>{label}</h2>
        </div>
        <Image
          src={valueSearched.value}
          alt={caption || "no caption found"}
          width={60}
          height={0}
          style={{ height: "auto", minWidth: "60px" }}
        />
      </div>
    ),
  };

  return (
    <div className={styles.infoSection}>
      {valueDisplay[valueSearched.type]}

      <div className={styles.paginationWrapper}>
        <Pagination
          size="small"
          page={currentPage}
          count={Math.ceil(paginationCount / itemsPerPage)}
          onChange={handlePageChange}
        />
      </div>

      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        setIsOpen={setIsConfirmationModalOpen}
        onConfirm={handleClaimDomain}
      >
        <div>
          <p className={styles.confirmationmessage}>
            Are you sure you would like to claim{" "}
            <span className={styles.domainName}>{valueSearched.value}</span> as
            your own domain?
          </p>
        </div>
      </ConfirmationModal>
    </div>
  );
};

export default InfoSection;
