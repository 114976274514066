import React from "react";
import styles from "./ConfirmationModal.module.scss";
import { ClickAwayListener } from "@mui/material";
import Button from "../Button/Button";

interface ConfirmationModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  children?: React.ReactNode;
  onConfirm: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  setIsOpen,
  children,
  onConfirm,
}) => {
  return (
    <>
      {isOpen && (
        <div className={styles.wrapper}>
          <ClickAwayListener onClickAway={() => setIsOpen(false)}>
            <div className={styles.content}>
              {children}
              <Button
                onClick={() => {
                  onConfirm();
                  setIsOpen(false);
                }}
              >
                Confirm
              </Button>
            </div>
          </ClickAwayListener>
        </div>
      )}
    </>
  );
};

export default ConfirmationModal;
