import React from "react";
import styles from "./TableControls.module.scss";
import CheckBox from "@/components/CheckBox/CheckBox";
import InfoIcon from "@mui/icons-material/Info";
import Select from "@/components/Select/Select";
import { inDatasetFilterOptions, mediaTypeFilterOptions } from "@/utils/consts";
import { AuthPhase, ItemDataType } from "@/types";
import { Tooltip } from "@mui/material";
import Button from "@/components/Button/Button";
import { useAppSelector } from "@/app/GlobalRedux/store";
import { useDispatch } from "react-redux";
import { setAuthenticationPhase } from "@/app/GlobalRedux/Features/authSlice";
import { setAuthModalsState } from "@/app/GlobalRedux/Features/authModalsSlice";

interface TableControlsProps {
  totalItems: number;
  displayedItems: number;
  checkedItems: number;
  itemsPerPage: number;
  pageNumber: number;
  itemsPerPageOptions: {
    value: number;
    name: string;
  }[];
  setItemsPerPage: React.Dispatch<React.SetStateAction<number>>;
  handleOnSelectAllClick: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleKeyDownSelectAll: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  isDoNotTrainList?: boolean;
  isSimilarMatchesPage?: boolean;
  handleOptingOutItems?: () => Promise<void>;
  handleRemovingItemsFromOptOuts?: () => Promise<void>;

  // filtering by media type
  mediaTypeValueToFilterBy?: ItemDataType | "all";
  setMediaTypeValueToFilterBy?: React.Dispatch<
    React.SetStateAction<ItemDataType | "all">
  >;

  // filtering by if item was found in dataset or not
  inDatasetValueToFilterBy?: "all" | "inDataset";
  setInDatasetValueToFilterBy?: React.Dispatch<
    React.SetStateAction<"all" | "inDataset">
  >;
  numberOfItemsInDataset?: number;
}

const TableControls: React.FC<TableControlsProps> = ({
  totalItems,
  displayedItems,
  checkedItems,
  itemsPerPage,
  pageNumber,
  itemsPerPageOptions,
  setItemsPerPage,
  handleOnSelectAllClick,
  handleKeyDownSelectAll,
  isDoNotTrainList,
  isSimilarMatchesPage,
  handleOptingOutItems,
  handleRemovingItemsFromOptOuts,
  // filtering
  mediaTypeValueToFilterBy,
  setMediaTypeValueToFilterBy,
  inDatasetValueToFilterBy,
  setInDatasetValueToFilterBy,
  numberOfItemsInDataset,
}) => {
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();

  const handleItemsPerPageSelectChange = (
    target:
      | (EventTarget & HTMLInputElement)
      | (EventTarget & { value: unknown; name: string })
  ) => {
    setItemsPerPage(Number(target.value));
  };

  const handleMediaFilterSelectChange = (
    target:
      | (EventTarget & HTMLInputElement)
      | (EventTarget & { value: unknown; name: string })
  ) => {
    setMediaTypeValueToFilterBy &&
      setMediaTypeValueToFilterBy(target.value as "all" | ItemDataType);
  };

  const handleInDatasetFilterSelectChange = (
    target:
      | (EventTarget & HTMLInputElement)
      | (EventTarget & { value: unknown; name: string })
  ) => {
    setInDatasetValueToFilterBy &&
      setInDatasetValueToFilterBy(target.value as "all" | "inDataset");
  };

  const handleOptOutClick = async () => {
    if (!isAuthenticated) {
      dispatch(setAuthenticationPhase(AuthPhase.LOGIN));
      dispatch(setAuthModalsState({ isLogInModalOpen: true }));
      return;
    }

    handleOptingOutItems && handleOptingOutItems();
  };

  return (
    <div className={styles.controlsWrapper}>
      <div className={styles.controlsContent}>
        <div className={styles.actionsWrapper}>
          <CheckBox
            isSelectAllCheckbox
            handleOnSelectAllClick={handleOnSelectAllClick}
            handleKeyDownSelectAll={handleKeyDownSelectAll} 
            labelText="Select All"
          />
          <Select
            value={itemsPerPage}
            defaultValue={itemsPerPageOptions[2].value}
            onChange={handleItemsPerPageSelectChange}
            options={itemsPerPageOptions}
          />

          <div className={styles.paginationInfo}>
            <span>
              {(itemsPerPage * (pageNumber-1))+1} -{" "}
              {(itemsPerPage * (pageNumber-1)) + displayedItems} of {totalItems}
            </span>
          </div>
        </div>
        {(setMediaTypeValueToFilterBy || setInDatasetValueToFilterBy) && (
          <div className={styles.filteringSelectWrappers}>
            {mediaTypeValueToFilterBy && (
              <div className={styles.selectWrapper}>
                <Select
                  value={mediaTypeValueToFilterBy}
                  defaultValue={mediaTypeFilterOptions[0].value}
                  options={mediaTypeFilterOptions}
                  onChange={handleMediaFilterSelectChange}
                />
              </div>
            )}

            {inDatasetValueToFilterBy && (
              <div className={styles.selectWrapper}>
                <Select
                  disabled={numberOfItemsInDataset === 0}
                  value={inDatasetValueToFilterBy}
                  defaultValue={inDatasetFilterOptions[0].value}
                  options={inDatasetFilterOptions}
                  onChange={handleInDatasetFilterSelectChange}
                />

                <Tooltip
                  title={`${numberOfItemsInDataset} ${
                    numberOfItemsInDataset === 1 ? "item" : "items"
                  } found in
                    dataset`}
                >
                  <InfoIcon fontSize="small" />
                </Tooltip>
              </div>
            )}
          </div>
        )}

        {isSimilarMatchesPage && (
          <div className={styles.doNotTrainButtonWrapper}>
            Remove {checkedItems}
            {`${checkedItems === 1 ? " item " : " items "}`} from
            <Button
              disabled={checkedItems === 0}
              onClick={handleRemovingItemsFromOptOuts}
            >
              Similar matches
            </Button>
          </div>
        )}

        {isDoNotTrainList ? (
          <div className={styles.doNotTrainButtonWrapper}>
            Remove {checkedItems}
            {`${checkedItems === 1 ? " item " : " items "}`} from your:
            <Button
              disabled={checkedItems === 0}
              onClick={handleRemovingItemsFromOptOuts}
            >
              Do Not Train list
            </Button>
          </div>
        ) : (
          <div className={styles.doNotTrainButtonWrapper}>
            Mark {checkedItems}
            {`${checkedItems === 1 ? " item " : " items "}`} as:
            <Button disabled={checkedItems === 0} onClick={handleOptOutClick}>
              Do Not Train
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default TableControls;
