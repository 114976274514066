import React from "react";
import { Select as MUISelect, MenuItem } from "@mui/material";
import styles from "./Select.module.scss";

interface SelectProps {
  value: string | number;
  defaultValue: string | number;
  onChange: (
    target:
      | (EventTarget & HTMLInputElement)
      | (EventTarget & {
          value: unknown;
          name: string;
        })
  ) => void;
  options: {
    value: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    name: string;
  }[];
  disabled?: boolean;
}

const Select: React.FC<SelectProps> = ({
  value,
  defaultValue,
  onChange,
  options,
  disabled,
}) => {
  return (
    <div>
      <MUISelect
        disabled={disabled}
        value={value}
        className={styles.select}
        defaultValue={defaultValue}
        onChange={({ target }) => {
          onChange(target);
        }}
        sx={{
          fontFamily: "var(--font-forma)",
          fontSize: "14px",
        }}
      >
        {options.map(({ value, name }, idx) => (
          <MenuItem
            key={`${name}-${idx}`}
            value={value}
            sx={{
              fontFamily: "var(--font-forma)",
              fontSize: "14px",
            }}
          >
            {name}
          </MenuItem>
        ))}
      </MUISelect>
    </div>
  );
};

export default Select;
