import React from "react";
import { Pagination } from "@mui/material";
import styles from "./PaginationWrapper.module.scss";

interface PaginationWrapperProps {
  currentPage: number;
  paginationCount: number;
  itemsPerPage: number;
  handlePageChange: (event: React.ChangeEvent<unknown>, value: number) => void;
}

const PaginationWrapper: React.FC<PaginationWrapperProps> = ({
  currentPage,
  paginationCount,
  itemsPerPage,
  handlePageChange,
}) => {
  return (
    <div className={styles.paginationWrapper}>
      <Pagination
        page={currentPage}
        count={Math.ceil(paginationCount / itemsPerPage)}
        onChange={handlePageChange}
      />
    </div>
  );
};

export default PaginationWrapper;
